<template>
  <div class="staff" @click="showStaffDetail" role="button">
    <div class="staff-left">
      <v-avatar
        class="avator"
        v-if="
          (data.image != 'avatar.jpg' &&
            data.image != '' &&
            data.image != undefined) ||
          data.photo
        "
      >
        <!-- <img
          :src="'files/' + (data.image ? data.image : data.photo)"
          :alt="data.name"
        /> -->
        <newImg :fileUrl="'files/' + (data.image ? data.image : data.photo)"></newImg>
      </v-avatar>
      <div v-else>
        <v-avatar
          :color="avatarBg"
          class="avator x-btn"
          v-if="data.firstName"
          style="color: #fff"
        >
          <span class="white--text" style="font-size: 16px">
            {{
              data.firstName[0].toUpperCase() +
              " " +
              data.lastName[0].toUpperCase()
            }}
          </span>
        </v-avatar>
        <v-avatar :color="avatarBg" class="avator x-btn" v-else>
          <span class="white--text" style="font-size: 16px">
            {{ data.name[0].toUpperCase() }}
          </span>
        </v-avatar>
      </div>
      <div class="rightContent">
        <div class="name">
          <p>{{ data.name }}</p>
          <img
            src="@/assets/images/map/star@2x.png" 
            :alt="data.name"
            v-if="data.isFavorite == true && showFavoriteFunction == true"
          >
        </div>
        <div style="color: #6c6c6c; font-size: 12px" class="global-title">
          {{ data.title }}
        </div>
        <div style="color: #1d1d1d; font-size: 12px" class="global-company">
          {{ data.company }}
        </div>
        <div style="color: #263a45; font-size: 12px" class="global-departemt">
          {{ data.department }}
        </div>
        <div
          style="float: left"
          class="attributes"
          v-if="
            data.skills &&
            data.skills.length > 0 &&
            showAttributesFunction == true
          "
        >
          <span v-for="i in data.skills" @click.stop="attritutesBtn(i)">{{
            i
          }}</span>
        </div>
        <!-- <div style="display: flex; align-items: center">
          <i class="iconfont icon-dibiao" v-if="data.checkedInCampus"></i>
          <p style="margin-top: 20px; font-size: 12px; color: #263a45">
            {{ data.checkedInCampus }}
          </p>
        </div> -->
      </div>
    </div>
    
    <!-- <i
      class="iconfont icon-xingxingfull"
      v-if="data.isFavorite == true && showFavoriteFunction == true"
      style="margin-right:30px"
    ></i> -->
    <!-- <i class="iconfont icon-xingxingkong" v-else style="margin-right:30px"></i> -->
    <div class="checkbox" v-show="showCheckbox">
      <v-checkbox v-model="data.chosen" @change="selectPa"></v-checkbox>

      <!--<v-checkbox-->
      <!--  v-model="checkbox"-->
      <!--  @change="selectPa"-->
      <!--&gt;</v-checkbox>-->
    </div>
    <div class="cancel"  style="width: 20%;text-align: right;">
      <!-- <img
        src="@/assets/images/map/star@2x.png" 
        :alt="data.name"
        v-if="data.isFavorite == true && showFavoriteFunction == true"
        style="margin-right:10px"
      > -->
      <div class="checkInDiv" v-show="data.checkedInCampus && data.checkedInCampus != '' && data.globalShowCheckIn == 'true'">
        <img
          src="@/assets/images/map/checkmark@2x.png" 
          :alt="data.name"
          
        >
        <p>Checked in</p>
        <p style="font-size: 12px; color: #263a45">
          {{ data.checkedInCampus }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import newImg from "@/views/dashboard/componentsHa/img";
export default {
  name: "globalStaff",
  props: {
    // 搜索出来的人员
    data: Object,
    // 右侧勾选框
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    showAttributesFunction: {
      type: Boolean,
      default: false,
    },
    showFavoriteFunction: {
      type: Boolean,
      default: false,
    },
    // 右侧删除图标
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    newImg
  },
  data() {
    return {
      checkbox: false,
      avatarBg: "#707070",
    };
  },
  created() {
    if (localStorage.client && localStorage.client == "adobe") {
      this.avatarBg = "#1b959a";
    }
  },
  methods: {
    showStaffDetail() {
      this.$emit("showStaffDetail", this.data);
    },
    // 勾选与会人员
    selectPa(data) {
      this.$emit("selectPa", this.data);
    },
    cancel(data) {
      this.$emit("cancel", this.data);
    },
    attritutesBtn(i) {
      this.$emit("attritutesSearch", i);
    },
  },
};
</script>

<style scoped lang="scss">
.staff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  // border-bottom: 1px solid #d5d5d5;
  .staff-left {
    display: flex;
    // align-items: center;
    width: 80%;
    .avator {
      margin-left:10px;
      margin-right: 1rem;
    }

    .rightContent {
      text-align: left;
      height: auto;
      .name {
        font-weight: bold;
        // display: flex;
        // align-items: center;
        p {
          margin: 0;
          display: inline-block;
        }
        img {
          // float: right;
          width: 15px;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }

  .checkbox {
    float: right;
  }
  &:first-child {
    // border-top: 1px solid #d5d5d5;
  }
  .checkInDiv {
    p {
      display: inline-block;
      font-size: 12px;
      margin-left: 5px;
      margin-bottom: 0;
      // word-break: break-all;
    }
    img {
      display: inline-block;
      width: 15px;
    }
  }
}
.attributes {
  span {
    display: inline-block;
    border: 1px solid #393933;
    border-radius: 20px;
    margin: 5px;
    padding: 3px 15px;
    color: #1473e6;
    font-size: 12px;
  }
}
</style>
